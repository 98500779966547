<template>
  <div class="flex items-center gap-1">
    <!-- indicator -->
    <PeriodDiffIcon :class="`transform ${periodDiffClass} ${polarityClass}`" />
    <BaseText
      type="label"
      size="sm"
      :class="polarityClass"
    >
      {{ percentFormatted }}
    </BaseText>
    <BaseText
      size="sm"
      class="text-text-normal truncate"
    >
      {{ periodText }}
    </BaseText>
  </div>
</template>

<script>
import PeriodDiffIcon from '../globals/Icons/LensIcons/PeriodDiffIcon.vue'

export default {
  name: 'PercentageChangeIndicator',
  components: {
    PeriodDiffIcon
  },
  props: {
    periodText: {
      type: String,
      default: () => 'since last period'
    },
    percent: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {}
  },
  computed: {
    percentFormatted () {
      return isFinite(this.percent) ? `${this.percent?.toFixed(1)}%` : 'N/A'
    },
    polarityClass () {
      if (this.percent === 0 || !isFinite(this.percent)) return 'text-text-subdued'
      const isPositive = this.percent > 0
      // return isPositive ? 'text-secondary-green-200' : 'text-secondary-red-200'
      return isPositive ? 'text-secondary-green-200' : 'text-text-muted'
    },
    periodDiffClass () {
      const isPositive = this.percent > 0
      return isPositive ? '' : 'rotate-180'
    }
  }
}
</script>
