<template>
  <MainLayout
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <div
        v-if="isBetaUser"
        class="search relative flex items-center justify-between px-5 py-3.5 border-b border-border-normal"
      >
        <div class="flex items-center">
          <img
            src="../../assets/icons/lensIcon.png"
            class="w-10 h-10 rounded-md"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Lens
            </BaseText>
          </div>
        </div>
        <div
          class="group flex items-center gap-1.5 w-full search-container px-2 text-sm rounded-md search-input-container"
          @mouseenter="searchBarHovered = true"
          @mouseleave="searchBarHovered = false"
        >
          <DynamicSearchIcon
            class="flex-shrink-0"
            :is-hovered="searchBarHovered || isSearchFocussed"
          />
          <!-- Search Input -->
          <input
            v-model="textSearch"
            type="text"
            class="w-full search-input outline-none border-none text-black h-8"
            placeholder="Search ad accounts and lenses"
            @input="debounceSearch"
            @focus="isSearchFocussed = true"
            @blur="isSearchFocussed = false"
          >
          <BaseLoadingSpinnerCircle
            v-if="loading.search"
            class="text-icon-normal"
          />
          <div
            v-else-if="!isSearchFocussed"
            class="flex items-center px-2 py-0.5 gap-px transition-colors duration-100 text-text-subdued group-hover:text-text-muted"
          >
            <BaseText
              type="label"
              size="xs"
            >
              ⌘
            </BaseText>
            <BaseText
              type="body"
              size="xs"
            >
              K
            </BaseText>
          </div>
        </div>
        <button
          class="black-btn-primary pl-2 py-1.5 pr-3 rounded-md"
          primary
          icon-only
          @click="showLensCreationModal = true"
        >
          <span class="flex gap-1.5 pr-1.5">
            <PlusIcon stroke="white" />
            <BaseText
              type="label"
              size="sm"
              class="text-white"
            >
              Create New
            </BaseText>
          </span>
        </button>
      </div>
    </template>

    <template #content>
      <div class="p-4">
        <Transition
          name="fade"
          mode="out-in"
        >
          <!-- Lens Beta Application -->
          <div
            v-if="!isBetaUser"
            class="flex flex-col gap-1 items-center justify-center w-full h-full mt-32"
          >
            <video
              autoplay
              loop
              muted
              playsinline
              class="h-56 rounded-md mb-11 object-cover"
            >
              <source
                src="https://publicassets.foreplay.co/animated-icon-lens.webm"
                type="video/webm"
              >
            </video>
            <BaseText
              type="heading"
              size="overline"
              class="text-text-muted uppercase -mt-14"
            >
              Get Early Access
            </BaseText>
            <BaseText
              type="heading"
              size="lg"
              class="text-text-muted"
            >
              Performance Reporting Through a Creative Lens
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              Bring performance and creative teams together with automated reporting and creative insights.
            </BaseText>
            <a
              href="https://share-na3.hsforms.com/2NC5-yAnQTheEYBNneyuD4w5nb136"
              target="_blank"
            >
              <BaseButton
                class="mt-8"
                size="sm"
                primary
              >
                Request Access
              </BaseButton>

            </a>
          </div>
          <div
            v-else-if="loading.insights"
            class="flex flex-col gap-1 items-center justify-center w-full h-full mt-32"
          >
            <BaseLoadingSpinnerCircle
              :width="24"
              :height="24"
              class="text-text-muted"
            />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Loading ad accounts...
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal"
            >
              Hang tight! This won't take long.
            </BaseText>
          </div>
          <LensEmptyState
            v-else-if="textSearch.length === 0 && searchLens.length === 0"
            @createLens="showLensCreationModal = true"
          />
          <div
            v-else-if="searchLens.length === 0"
            class="flex flex-col items-center w-full justify-center mt-24"
          >
            <SearchIcon class="text-icon-normal w-8 h-8" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted mt-4"
            >
              No results found
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-subdued mt-1"
            >
              Try changing your search!
            </BaseText>
          </div>
          <div v-else>
            <transition-group
              appear
              name="fade"
              tag="ul"
              class="space-y-4"
            >
              <div
                v-for="(lens,index) in searchLens"
                :key="`lens-${lens.id}-${index}`"
                :style="{ transitionDelay: textSearch.length > 0 ? '0ms' : `${index*50}ms` }"
              >
                <LensOverviewCard :data="lens" />
              </div>
            </transition-group>
            <infinite-loading
              v-if="!isTotalQueried"
              :identifier="infiniteId"
              @infinite="getMoreLens"
            >
              <div slot="spinner">
                <BaseLoadingLogo
                  :margin="2"
                />
              </div>
              <div slot="no-more" />
              <div slot="no-results" />
            </infinite-loading>
          </div>
        </Transition>
      </div>
      <!-- Modals -->
      <LensCreationModal
        v-if="showLensCreationModal"
        @complete="handleLensCreation"
        @close="showLensCreationModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
import firebase from 'firebase/app'
import FirebaseAPI from '@/api/firebase'
import LensAPI from '@/api/lens'
import axios from 'axios'
// Components
import MainLayout from '../../layouts/MainLayout.vue'
import LensOverviewCard from '../../components/lens/LensOverviewCard.vue'
import LensEmptyState from '../../components/lens/LensEmptyState.vue'
import LensCreationModal from '../../components/lens/lensCreationModal/LensCreationModal.vue'
import InfiniteLoading from 'vue-infinite-loading'
// Icons
import DynamicSearchIcon from '../../components/globals/Icons/SearchIcons/DynamicSearchIcon.vue'
import PlusIcon from '../../components/globals/Icons/PlusIcon.vue'
import SearchIcon from '../../components/globals/Icons/SearchIcon.vue'

export default {
  name: 'LensView',
  components: {
    LensCreationModal,
    LensEmptyState,
    MainLayout,
    LensOverviewCard,
    InfiniteLoading,
    // Icons
    PlusIcon,
    DynamicSearchIcon,
    SearchIcon
  },
  data () {
    return {
      showLensCreationModal: false,

      hasMounted: false,

      loading: {
        insights: false,
        search: false
      },

      textSearch: '',
      searchLens: [],

      // Used to default a no search - initialized on mount
      defaultSearch: {
        lens: [],
        totalItems: 0
      },
      isSearchFocussed: false,
      searchBarHovered: false,

      infiniteId: +new Date(),
      pagination: {
        currentPage: 1,
        totalItems: 0
      }
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getFolders']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('LensModule', ['getLenses', 'getShowLensCreationModal']),
    ...mapGetters('AuthModule', ['isBetaUser']),
    isTotalQueried () {
      return this.searchLens.length === this.pagination.totalItems
    },
    sortedFolders () {
      // We want empty folders (besides the default folder) to be at the bottom
      const nonEmptyFolders = this.getFolders.filter(folder => (folder?.lenses?.length || 0) > 0)
      const emptyFolders = this.getFolders.filter(folder => (folder?.lenses?.length || 0) === 0)
      return [{ id: null, name: 'Default Folder' }, ...nonEmptyFolders, ...emptyFolders]
    }
  },
  watch: {
    getShowLensCreationModal (newValue) {
      if (newValue) {
        this.showLensCreationModal = true
        this.SET_SHOW_LENS_CREATION_MODAL(false)
      }
    }
  },
  async mounted () {
    await this.fetchLensInsights()
    this.showLensCreationModal = this.getShowLensCreationModal
    this.SET_SHOW_LENS_CREATION_MODAL(false)
    this.hasMounted = true
  },
  methods: {
    ...mapActions('LensModule', ['fetchLenses', 'setShowLensCreationModal']),
    ...mapMutations('LensModule', ['SET_SHOW_LENS_CREATION_MODAL']),
    handleSearchInput: _.debounce(async function (event) {
      try {
        const searchResult = await LensAPI.AdGroups.getLensInsights({ search: event.target.value })
        this.pagination = {
          currentPage: 1,
          totalItems: searchResult.total
        }
        this.pagination.totalItems = searchResult.total
        this.searchLens = searchResult.data
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Detected change and cancelled search')
        }
      } finally {
        this.loading.search = false
      }
    }, 300),
    debounceSearch (event) {
      LensAPI.AdGroups.abortInsightSearchRequest()
      if (!event.target.value) {
        this.searchLens = this.defaultSearch.lens
        this.pagination = {
          currentPage: 1,
          totalItems: this.defaultSearch.totalItems
        }
        return
      }
      this.loading.search = true
      this.handleSearchInput(event)
    },
    async fetchLensInsights (load = true) {
      this.loading.insights = load
      try {
        const res = await LensAPI.AdGroups.getLensInsights()
        this.searchLens = res.data
        if (!this.hasMounted) {
          this.defaultLenses = res.data
          this.defaultSearch = {
            lens: res.data,
            totalItems: res.total
          }
        }
        this.pagination.totalItems = res.total
        await this.$nextTick() // Ensures Vue updates the DOM before transitions run
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Detected change and cancelled search')
        }else{
          console.error(e)
          this.$showAlert({
            type: 'error',
            message: 'An unexpected error occurred'
          })
        }
      } finally {
        this.loading.insights = false
        this.infiniteId += 1
      }
    },
    async getMoreLens () {
      try {
        const payload = {
          ...(this.textSearch.length > 0 && { search: this.textSearch }),
          page: this.pagination.currentPage + 1
        }
        const res = await LensAPI.AdGroups.getLensInsights(payload)
        this.pagination = {
          currentPage: this.pagination.currentPage + 1,
          totalItems: res.total
        }
        this.searchLens = this.searchLens.concat(res.data)
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Detected change and cancelled search')
        }else{
          console.error(e)
          this.$showAlert({
            type: 'error',
            message: e.message
          })
        }
      }
    },
    async handleLensCreation ({ payload, folderId }) {
      try {
        const { data } = await LensAPI.Lens.create(payload)
        const newLensId = data.id
        // On first lens creation - init a default performance preset
        if (this.getLenses.length === 0) {
          const basePreset = {
            name: 'Performance',
            description: 'View key ad performance metrics like results, reach, impressions, and cost per result to track campaign effectiveness.',
            is_default: true,
            table_columns: ['spend', 'impressions', 'cpm', 'cpc', 'roas', 'ctr']
          }
          const allMetrics = {
            name: 'All Metrics',
            description: 'A preset containing all available metrics',
            table_columns: Object.keys(this.getMetricLookup),
            is_default: false
          }

          await Promise.all([
            LensAPI.LensPreset.create(basePreset),
            LensAPI.LensPreset.create(allMetrics)
          ])
        }

        if (folderId) {
          // First get the folder to check if lenses array exists
          const folderDoc = await FirebaseAPI.Folders.getByID(folderId)
          if (folderDoc) {
            if (folderDoc.lenses) {
              // If lenses array exists, use arrayUnion
              await FirebaseAPI.Folders.update(folderId, {
                lenses: firebase.firestore.FieldValue.arrayUnion(newLensId)
              })
            } else {
              // If lenses array doesn't exist, initialize it
              await FirebaseAPI.Folders.update(folderId, {
                lenses: [newLensId]
              })
            }
          }
        }
        await this.fetchLensInsights()
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    },
    formatDate (dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    getInitials (name) {
      if (!name) return ''
      return name.split(' ').map(word => word[0]).join('').toUpperCase().substring(0, 2)
    }
  }
}
</script>

<style scoped>
.search-container {
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #EBEFF3;
  background: linear-gradient(90deg, rgba(76, 76, 82, var(--gradient-opacity)) 0%, rgba(52, 52, 59, var(--gradient-opacity)) 100%);
  transition: box-shadow 100ms ease-in-out, --gradient-opacity 100ms ease-in-out;
  width: 100%;
  max-width: 528px;
}
.search-input {
  color: black;
  transition: color 100ms ease-in-out;

  /* Remove default browser styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  outline: none;

  /* typography */
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.search-input:focus {
  /* Remove default browser styles */
  outline: none;
  border: none;
  box-shadow: none;
}

.search-input-container:hover, .search-input-container:focus-within {
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #808899;
}

.fade-enter-active, .fade-leave-active {
  transition: all 150ms ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
