<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6 10H2"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <rect
      x="6.75"
      y="4.75"
      width="6.5"
      height="10.5"
      rx="3.25"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M18 10H14"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M10 8L10 12"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'BenchmarkIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
