<template>
  <div class="p-5">
    <div class="border border-border-normal rounded-md flex flex-col gap-5 p-2.5">
      <div>
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted mb-1"
        >
          Ad Account
        </BaseText>
        <div class="w-full flex items-center justify-between gap-2 border border-border-normal rounded-lg px-2 py-1.5">
          <div class="flex items-center gap-2">
            <img
              src="../../../assets/images/meta.svg"
              class="w-4 h-4"
            >
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted font-medium"
            >
              {{ selectedAccount.label }}
            </BaseText>

            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              {{ selectedAccount.value.replace('act_', '') }}
            </BaseText>
          </div>

          <div>
            <div class="flex items-center gap-2">
              <div class="w-1.5 h-1.5 border border-secondary-green-200 bg-secondary-green-200 rounded-full" />
              <BaseText
                type="body"
                size="sm"
                class="text-secondary-green-200 font-medium"
              >
                Connected
              </BaseText>
            </div>
          </div>
        </div>
      </div>

      <!-- Goal Metric -->
      <div class="w-full flex flex-col">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted mb-1"
        >
          Goal Metric
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal mb-2.5"
        >
          Choose a default metric to enable relevant insights to your goals.
        </BaseText>
        <!-- Dropdown Opener -->
        <div
          v-on-clickaway="() => showGoalMetricDropdown = false"
          class="relative"
        >
          <button
            class="goal-metric-button flex items-center gap-1 w-full px-2 py-1.5 bg-white flex-nowrap whitespace-nowrap rounded-lg focus:outline-none"
            @click="showGoalMetricDropdown = true"
          >
            <BaseText
              type="body"
              size="sm"
              class="flex-grow text-left truncate mr-0.5"
              :class="form?.goalMetric?.length ? 'text-text-muted' : 'text-text-normal'"
            >
              {{ form?.goalMetric?.length ? getMetricLookup?.[form.goalMetric]?.name : 'Select Metric' }}
            </BaseText>
            <ChevronIcon class="text-icon-normal" />
          </button>
          <transition>
            <div
              v-if="showGoalMetricDropdown"
              class="goal-metric-dropdown"
            >
              <PropertySelectDropdown
                search-placeholder="Search Metrics"
                metrics-only
                emit-keys-only
                use-parent-width
                :selected-metrics="form?.goalMetric?.length ? [form.goalMetric] : []"
                @optionSelected="handleSelectGoalMetric"
                @close="showGoalMetricDropdown = false"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- Attribution Window -->
      <div>
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted mb-1"
        >
          Attribution Window
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal mb-1 w-full"
        >
          The time frame in which a user action can be credited for a conversion. Helps track the effectiveness of your campaigns.
        </BaseText>
        <div class="flex items-center gap-2">
          <BaseSingleDropdown
            :options="clickAttributionWindows"
            class="z-10 flex-1"
            option-key="label"
            :selected-obj="form.attributionWindowClickType"
            placeholder="Click"
            use-parent-width
            @change="updateFormField('attributionWindowClickType', $event)"
          />
          <BaseSingleDropdown
            :options="viewAttributionWindows"
            class="z-10 flex-1"
            option-key="label"
            :selected-obj="form.attributionWindowViewType"
            placeholder="View"
            use-parent-width
            @change="updateFormField('attributionWindowViewType', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import PropertySelectDropdown from '../filters/PropertySelectDropdown.vue'

import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'

export default {
  name: 'ConnectionSettings',
  components: {
    PropertySelectDropdown,
    ChevronIcon
  },
  mixins: [clickaway],
  props: {
    form: {
      type: Object,
      required: true
    },
    selectedAccount: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    // Option Data
    clickAttributionWindows: [
      { label: '1 Day Click', value: '1d' },
      { label: '7 Days Click', value: '7d' },
      { label: '28 Days Click', value: '28d' }
    ],
    viewAttributionWindows: [
      { label: '1 Day View', value: '1d' },
      { label: '7 Days View', value: '7d' },
      { label: '28 Days View', value: '28d' }
    ],
    showGoalMetricDropdown: false
  }),
  watch: {
    form: {
      deep: true,
      handler (newForm) {
        const isValid = this.validateForm(newForm)
        this.$emit('form-validity', isValid)
      }
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup'])
  },
  created () {
    // Check initial form validity
    this.$emit('form-validity', this.validateForm(this.form))
  },
  methods: {
    updateFormField (field, value) {
      this.$emit('update:form', { ...this.form, [field]: value })
    },
    handleSelectGoalMetric (key) {
      this.updateFormField('goalMetric', key)
      this.$nextTick(() => {
        this.showGoalMetricDropdown = false
      })
    },
    validateForm (formData) {
      return !!(
        formData.goalMetric &&
        formData.attributionWindowClickType &&
        formData.attributionWindowViewType
      )
    }
  }
}
</script>

<style scoped>
.goal-metric-button {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
  border-radius: 8px;
}
.goal-metric-button:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.goal-metric-dropdown {
  position: absolute;
  top: -4px;
  left: -2px;
  right: -2px;
  z-index: 500;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
