<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 10C12.25 11.2427 11.2427 12.25 10 12.25C8.75732 12.25 7.75 11.2427 7.75 10C7.75 8.75732 8.75732 7.75 10 7.75C11.2427 7.75 12.25 8.75732 12.25 10Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.123 9.32096C13.4949 3.2263 6.50493 3.22637 2.87687 9.32103C2.62796 9.73923 2.62796 10.2609 2.87687 10.6791C6.50493 16.7737 13.4949 16.7736 17.123 10.679C17.3719 10.2608 17.3719 9.73908 17.123 9.32096Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PreviewIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
