<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 9.75V12.6667M16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 6.83337C9.72387 6.83337 9.5 7.05723 9.5 7.33337C9.5 7.60951 9.72387 7.83337 10 7.83337C10.2761 7.83337 10.5 7.60951 10.5 7.33337C10.5 7.05723 10.2761 6.83337 10 6.83337Z" 
    fill="currentColor" stroke="currentColor" stroke-width="0.66"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoSimpleIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
