<template>
  <!-- Main Container -->
  <div class="h-full">
    <!-- Central Text -->
    <div
      class="flex flex-col items-center justify-center"
      style="margin-top: max(0px, min(228px, 30vh));"
    >
      <!-- Lens Img -->
      <img
        class="w-24"
        src="../../assets/images/LensOrbImage.png"
      >

      <!-- Lens Title -->
      <BaseText
        type="heading"
        size="lg"
        class="text-text-loud mt-5"
      >
        Welcome to Lens
      </BaseText>
      <!-- Lens Description -->
      <BaseText
        type="body"
        size="md"
        class="text-text-muted mt-1"
        style="max-width: 396px; text-align: center;"
      >
        Make sense of your ad data through a creative lens with beautiful AI-first creative reporting.
      </BaseText>

      <!-- Get Started Button -->
      <BaseButton
        class="mt-5"
        outlined
        @click="$emit('createLens')"
      >
        <div class="flex justify-between items-center gap-1.5">
          <MetaPlatformIcon />
          <BaseText
            class="text-text-muted"
            type="label"
            size="sm"
          >
            Create a Lens Brand
          </BaseText>
        </div>
      </BaseButton>
    </div>

    <!-- Bottom Cards -->
    <div class="flex items-center gap-4 p-4 justify-between mt-auto">
      <!--  -->
      <div class="h-40 w-full rounded-xl bg-black bg-opacity-10" />
      <div class="h-40 w-full rounded-xl bg-black bg-opacity-10" />
      <div class="h-40 w-full rounded-xl bg-black bg-opacity-10" />
    </div>
  </div>
</template>
<script>
import MetaPlatformIcon from '../globals/Icons/FilterIcons/Platforms/MetaPlatformIcon.vue'
export default {
  name: 'LensEmptyState',
  components: {
    MetaPlatformIcon
  }
}
</script>
